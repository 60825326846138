export default [
        // {
        //     path: '/catalogues/food-passport',
        //     name: 'food-passport',
        //     meta: {
        //         layout: 'session'
        //     },
        //     component: () => import('VIEWS/catalogues/FoodPassport.vue')
        // },
        {
            path: '/catalogues/recipes',
            name: 'catalogues-recipes',
            meta: {
                layout: 'session'
            },
            component: () => import('VIEWS/catalogues/Recipes.vue')
        },
        {
            path: '/catalogues/articles',
            name: 'catalogues-articles',
            meta: {
                layout: 'session'
            },
            component: () => import('VIEWS/catalogues/Articles.vue')
        },
        // {
        //     path: '/catalogues/aliments',
        //     name: 'catalogues-aliments',
        //     meta: {
        //         layout: 'session'
        //     },
        //     component: () => import('VIEWS/catalogues/Aliments.vue')
        // },
        {
            path: '/catalogues/prospects',
            name: 'catalogues-prospects',
            meta: {
                layout: 'session'
            },
            component: () => import('VIEWS/catalogues/Prospects.vue')
        },
        {
            path: '/catalogues/authors',
            name: 'catalogues-authors',
            meta: {
                layout: 'session'
            },
            component: () => import('VIEWS/catalogues/Authors.vue')
        },
        {
            path: '/catalogues/users',
            name: 'catalogues-users',
            meta: {
                layout: 'session'
            },
            component: () => import('VIEWS/catalogues/Users.vue')
        },
        {
            path: '/catalogues/branches',
            name: 'catalogues-branches',
            meta: {
                layout: 'session'
            },
            component: () => import('VIEWS/catalogues/Branches.vue')
        }
]