import Vue from 'vue'
import Vuex from 'vuex'
import sendRequest from '../libs/sendRequest'
import AES from '../libs/AES'

import { users } from './modules/users'
import { natural_users } from './modules/naturalUsers'
import { blog_categories } from './modules/blogCategories'
import { recipe_categories } from './modules/recipeCategories'
import { aliment_categories } from './modules/alimentCategories'
import { authors } from './modules/authors'
import { blog_posts } from './modules/blogPosts'
import { aliments } from './modules/aliments'
import { recipes } from './modules/recipes'
import { videos } from './modules/videos'
import { talks } from './modules/talks'
import { prospects } from './modules/prospects'
import { products } from './modules/products'
import { notifications } from './modules/notifications'
import { programs } from './modules/programs'
import { murals } from './modules/murals'
import { branches }from './modules/branches'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: null, 
    login: false,
    title: null,
	  alert: {
      open: false
    },
    multi_alert: {
        open: false,
        message: 'Error',
        variant: 'danger',
        color_text: 'white',
        timeout: null,
    },
    index_tab: 2
  },
  mutations: {
    setSession(state, payload) {
      state.session = payload
      sessionStorage.setItem('ns@session', JSON.stringify(payload))
    },
    deleteSession(state, payload) {
      sessionStorage.removeItem('ns@session')
      state.session = null;
    },
    setLogin(state, payload) {
      state.login = payload
    },
    setAlert(state, payload) {
      state.alert = payload
    },
    setMultiAlert: (state, new_alert) => {
        state.multi_alert.open = new_alert.open
        state.multi_alert.message = (new_alert.message !== undefined) ? new_alert.message : ''
        state.multi_alert.variant = (new_alert.variant !== undefined) ? new_alert.variant : 'success'
        state.multi_alert.text_color = (new_alert.text_color !== undefined) ? new_alert.text_color : 'white'
        state.multi_alert.timeout = (new_alert.timeout !== undefined) ? new_alert.timeout : 8000

        if(state.multi_alert.timeout !== null) {
            setTimeout(() => {
                state.multi_alert.open = false
            }, state.multi_alert.timeout)
        }
    },
    setTitle(state, payload){
      state.title = payload
    },
    setIndex(state, payload) {
      state.index_tab = payload
    }
  },
  actions: {
      async login({ commit}, payload) {


        const response = await sendRequest('portal/auth/login', 'POST', null, payload, null, null)
        

        if(response.success && response.data) {
          
          commit('setSession', response.data)
          commit('setLogin', true)

          commit('setAlert', {
            open: true,
            message: 'Bienvenid(a)!',
  		      text_color: 'success'
          })
        }

        if(!response.success && response.errors) {
          for(let error in response.errors) {
            console.log(response.errors[error])
          }
          commit('setLogin', false)
          commit('setAlert', {
            open: true,
            message: 'Nombre de usuario o contraseña incorrectos',
  		    text_color: 'danger'
          })
        }

        if(response.success && !response.data) {
          console.log('Se ha perdido la conexión con la API')
        }

      },

      // Alerts
      showAlert: ({commit}, messages) => {
          commit('setMultiAlert', {
              open: true,
              message: messages
          })
      },
      showError: ({commit}, messages) => {
          commit('setMultiAlert', {
              open: true,
              message: messages,
              variant: 'danger'
          })
      },
  },
  getters: {
    getHeaders (state) {
      console.log()
      return {
        ...CONFIG.server[CONFIG.env].headers,
        session: JSON.parse(sessionStorage.getItem('ns@session')).session
      }
    },
    getLogin(state) {
      return state.login
    },
    getSession(state) {
      try {
        let raw_session = JSON.parse(sessionStorage.getItem('ns@session')).session
        state.session = JSON.parse(AES.decrypt(Buffer.from(raw_session, 'base64')))
      }
      catch(e) {
        state.session = null
      }
      return state.session
    },
    getAlert(state) {
      return state.alert
    },
    getMultiAlert(state) {
      return state.multi_alert
    },
    getTitle(state) {
      return state.title
    },
    getIndex(state){
      return state.index_tab
    }

  },
  modules: {
    users,
    natural_users,
    aliment_categories,
    recipe_categories,
    blog_categories,
    authors,
    aliments,
    recipes,
    blog_posts,
    videos,
    talks,
    prospects,
    products,
    notifications,
    programs,
    murals,
    branches
  }
})
