import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import OneSignalVue from 'onesignal-vue'

global.CONFIG = require('./page.config.js')

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import './assets/scss/custom-bootstrap.scss'

// Make BSidebar available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//One signal
Vue.use(OneSignalVue)


// Lodash
import _ from 'lodash'
global._ = _

//Request
import _Request from './libs/sendRequest.js'
Vue.prototype.$sendRequest = _Request

import _informativeDate from './libs/informativeDate.js'
Vue.prototype.$informativeDate = _informativeDate


// Global components
import { Alert, AlertMulti, Input, Checkbox, GroupCheckbox, Tab, Chip, Table, Chart, Spinner, Modal, DropFile, Select, SelectImage } from './components/index.js'

Vue.component('ns-alert', Alert)
Vue.component('ns-multi-alert', AlertMulti)
Vue.component('ns-input', Input)
Vue.component('ns-checkbox', Checkbox)
Vue.component('ns-group-checkbox', GroupCheckbox)
Vue.component('ns-tab', Tab)
Vue.component('ns-chip', Chip)
Vue.component('ns-table', Table)
Vue.component('ns-chart', Chart)
Vue.component('ns-spinner', Spinner)
Vue.component('ns-modal', Modal)
Vue.component('ns-select', Select )
Vue.component('ns-select-image', SelectImage )
Vue.component('ns-dropfile', DropFile)


//TEMPLATES
import  DEFAULT  from './templates/default'
Vue.component('default', DEFAULT)

import SESSION from './templates/session'
Vue.component('session', SESSION)



Vue.config.productionTip = false

Vue.mixin({
    methods: {
        formatAmount(value, decimals = 2) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
        },
        isEmail (value) {
            let exp_reg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

            if(exp_reg.test(value)) {
                return true
            }
            else {
                return false
            }
        },
        formatDate(date){
            const raw_date = new Date(date).toLocaleDateString('en-US')

            let date_array = raw_date.split("/")
            date_array = date_array.reverse()

            if(date_array[2] < 10) {
                date_array[2] = `0${date_array[2]}`
            }
            
            if(date_array[1] < 10){
                date_array[1] = `0${date_array[1]}`
            }

            const date_string = `${date_array[0]}-${date_array[2]}-${date_array[1]}`

            return date_string
        },
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
