<template>
    <aside id="sidebar" :class="`sidebar bg-${bg_color}`">

        <section class="bg-white border py-5 row" >

            <div class="col-4 d-flex justify-content-center p-0">
                <img :src="avatar_ns" />
            </div>
            <div class="col-8 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0">
                        <p class="text-dark m-0 montserrat-bold">
                            {{ getSession.name }} 
                        </p>
                    </div>
                    <div class="col-12 p-0 m-0">
                        <p class="m-0 montserrat-regular" style="color: #707070">
                            {{ getSession.id_portal_role == 1 ? 'Administrador' : getSession.id_portal_role == 2 ? 'Editor' : getSession.id_portal_role == 3 ? 'Ventas' : ''}}
                        </p>
                    </div>
                </div>
            </div>
            
        </section>

        <!-- Inicio -->
        <section class="mt-5  nav__container">
            <nav class="row list-unstyled text-dark nav__container--list">

                <li v-for="item, index in menu" class="col-12" :key="index">

                    <div class="row" v-if="item.innermenu && item.permited_access.includes(getSession.id_portal_role)">

                        <div @click.stop="current_index = null; openMenu(index)"
                            class="col-12 p-0 my-1">
                            <div class="row">

                                <div class="col-2" v-if="!item.open">
                                    <img :src="gray_icon" />
                                </div>

                                <div class="col-2 p-0" v-if="item.open">
                                    <img :src="rectangle_icon"/>
                                    <img :src="green_icon" class="ml-2"/>
                                </div>

                                <div class="col-6 p-0 pl-1 montserrat-semibold">
                                    {{ item.label}} 
                                </div>
                                <div class="col-1">
                                    <img :src="arrow_down" v-if="!item.open" />
                                    <img :src="arrow_down" style="transform: rotateX(180deg);" v-if="item.open"/>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 p-0" v-if="item.title && item.open"  >
                            <div class="row">
                                <div class="col-2">
                                    <img :src="gray_icon" />
                                </div>
                                <div class="col-10 p-0 pl-1 montserrat-semibold">
                                        {{ item.title}} 
                                </div>
                            </div>
                        </div>

                        <div v-for="item_menu, index_menu in item.innermenu" :key="index_menu" v-if="item.open"
                            class="col-12 p-0">
                            <div class="row p-0">
                                <div class="col-2">

                                </div>
                                <div @click.stop="redirect(item_menu.route)" class="col-10 pl-1">
                                    {{ item_menu.label}} 
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row my-1" v-if="!item.innermenu && item.permited_access.includes(getSession.id_portal_role)">
                        <div class="col-2" v-if="current_index !== index">
                            <img :src="gray_icon" />
                        </div>

                        <div class="col-2 p-0" v-if="current_index == index">
                            <img :src="rectangle_icon"/>
                            <img :src="green_icon" class="ml-2"/>
                        </div>

                        <div @click.stop="current_index = index; redirect(item.route)"
                            class="col-10 p-0 pl-1 montserrat-semibold">
                            {{ item.label }} 
                        </div>
                    </div>
                    
                </li>

                <li class="col-12 p-0 pl-5 text-start pt-4">
                    <p @click.stop="logout"
                        class="text-start pl-2 montserrat-semibold text-danger cursor-pointer">
                        Salir
                    </p>
                </li>

            </nav>
        </section>
        

    </aside>
</template>

<script>
    //images
    import avatar_ns from 'ASSETS/icons/avatar_ns.png'
    import gray_icon from 'ASSETS/icons/grey_icon.png'
    import green_icon from 'ASSETS/icons/green_icon.png'
    import rectangle_icon from 'ASSETS/icons/rectangle.png'
    import arrow_down from 'ASSETS/icons/arrow_down.png'

    import { mapGetters } from 'vuex'
    

    export default {
        name: 'ns-sidebar',
        props: {
            bg_color: {
                type: String,
                required: false,
                default: 'sidebar',
                validator: function (value) {
                    let allow_colors = CONFIG.class_colors.concat(['sidebar'])
                    return allow_colors.includes(value)
                }
            }
        },
        data() {
            return {
                avatar_ns,
                gray_icon,
                green_icon,
                rectangle_icon,
                arrow_down,
                current_index: null,
                menu: [
                    {
                        label: 'Configuración',
                        title: 'Catálogos',
                        permited_access: [1],
                        open: false,
                        innermenu: [
                            // {
                            //     label: 'Pasaporte Alimenticio',
                            //     route: 'food-passport'
                            // },
                            {
                                label: 'Categorías Recetas',
                                route: 'catalogues-recipes'
                            },
                            {
                                label: 'Categorías Artículos',
                                route: 'catalogues-articles'
                            },
                            // {
                            //     label: 'Categorías Alimentos',
                            //     route: 'catalogues-aliments'
                            // },
                            {
                                label: 'Propectos',
                                route: 'catalogues-prospects'
                            },
                            {
                                label: 'Autores',
                                route: 'catalogues-authors'
                            },
                            {
                                label: 'Usuarios',
                                route: 'catalogues-users'
                            },
                            {
                                label: 'Sucursales',
                                route: 'catalogues-branches'
                            }
                        ]
                    },
                    {
                        label: 'Charlas',
                        route: 'talks',
                        permited_access: [1,2],
                    },
                    {
                        label: 'Artículos',
                        route: 'articles',
                        permited_access: [1,2],
                    },
                    {
                        label: 'Recetas',
                        route: 'recipes',
                        permited_access: [1,2],
                    },
                    // {
                    //     label: 'Tienda',
                    //     open: false,
                    //     innermenu: [
                    //         {
                    //             label: 'Ordenes',
                    //             route: 'orders'
                    //         },
                    //         {
                    //             label: 'Productos',
                    //             route: 'products'
                    //         }
                    //     ]
                    // },
                    {
                        label: 'Videos',
                        route: 'videos',
                        permited_access: [1,2],
                    },
                    {
                        label: 'Notificaciones',
                        route: 'notifications',
                        permited_access: [1,2,3],
                    },
                    {
                        label: 'Programa',
                        route: 'programs',
                        permited_access: [1,2],
                    },
                    {
                        label: 'Promociones',
                        route: 'murals',
                        permited_access: [1,3],
                    },
                    {
                        label: 'Control de usuarios',
                        route: 'control-users',
                        permited_access: [1],
                    }
                ]
            }
        },
        computed: {
            ...mapGetters([
                'getSession', 'getIndex'
            ]),

            current_env() {
                return CONFIG.env
            },
            current_route_name() {
                return this.$route.name
            }
        },
        methods: {
            redirect(route_name) {
                if(this.current_route_name !== route_name) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: route_name
                    })
                }
            },
            openMenu(index) {
                this.menu[index].open = !this.menu[index].open
            },
            async logout() {
                await this.$store.commit('deleteSession')
                this.redirect('login')
            }

        },
        mounted() {
            console.log('index:', this.getIndex)
            this.current_index = this.getIndex;

            if(this.current_index == 0 || this.current_index == 4){
                this.menu[this.current_index].open = true
            }
        }
    }
</script>

<style lang="scss" scoped>

    .sidebar {
        width:260px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 0;
   
    }

    
</style>
