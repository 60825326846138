<template>
    <section :id="tab_id" class="row bg-secondary btn-pill">
        <div :class="`col p-1`" v-for="(value, index) in data" :key="index">

            <button @click="clickChange(value)"
                :class="[`btn btn-pill btn-${size} full-width  h-100`, 
                (  (value.key !== undefined) ? 
                    tab_selected == value.key :
                    tab_selected == value) ? `btn-${color}` : 'btn-secondary']">

                    <span v-if="value.key !== undefined">
                        {{ value.label }}    
                    </span>
                    <span v-else>
                        {{ value }}
                    </span>
                    
            </button>

        </div>
    </section>
</template>

<script>
	export default {
		name: 'ad-tab',
        props: {
            tab_id: {
                type: String,
                required: false,
                default: 'tab-1',
            },
            data: {
                type: Array,
                required: true
            },
            tab_selected: {
                type: String,
                required: true
            },
            color: {
                type: String,
                description: 'defines the focus color of the inputs',
                required: false,
                default: 'primary',
                validator: function (value) {
                    let allow_colors = CONFIG.class_colors
                    return allow_colors.includes(value)
                }
            },
            size: {
                type: String,
                required: false,
                default: 'md',
                validator: function (value) {
                    let allow_size = ['xs', 'sm', 'md', 'lg']
                    return allow_size.includes(value)
                }
            }
        },
        data() {
            return {

            }
        },
        methods: {
            clickChange(value) {
                this.$emit('change', value)
            }
        }
	}
</script>

<style lang="scss">
	.tab {
        
	}
</style>