<template>
    <section id="multi_alert_container" 
        v-show="data_malert.open">

        <section :class="`alert ${data_malert.variant} flex-between-center fade-in`"
            v-for="(message, i_message) in messages" 
            :style="`animation-duration: ${200 * (i_message + 1)}ms;`">

            <div>
                <span :class="`ns--description-xs opensans-bold text-${data_malert.text_color}`">
                    {{ message }}
                </span>
            </div>
            <div>
                <span :class="`closebtn all_transition text-${data_malert.text_color}`">
                    &times;
                </span>
            </div>
        </section>

    </section>
</template>

<script>
    import _Store from '@/store'
    import { mapGetters } from 'vuex'

    export default {
        name: 'ns-multi-alert',
        computed: {
            ...mapGetters([
                'getMultiAlert'
            ]),

            data_malert() {
                console.log(this.getMultiAlert, 'getMultiAlert')
                return this.getMultiAlert
            },

            messages() {
                if(Array.isArray(this.data_malert.message)) {
                    return this.data_malert.message
                }
                else {
                    return new Array(this.data_malert.message)
                }
            }
        },
        data() {
            return {

            }
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>

    #multi_alert_container {
        max-width: 550px;
        width: 100%;
        position: fixed;
        right: 10px;
        top: 10px;
        z-index: 10000;
    }

    .alert {
        border-radius: 4px;
        padding: 10px;
        margin: 0px 0px 5px 0px;
        width: 100%;

        .closebtn {
            opacity: .5;
            font-size: 28px;
            line-height: 28px;
            font-weight: bold;
            cursor: pointer;
            float: right;
            margin-top: -4px;
        }

        .closebtn:hover {
            opacity: 1;
        }
    }

    .alert.primary { background-color: #1C355E; }
    .alert.secondary { background-color: #ced4da; }
    .alert.success { background-color: #00B560; }
    .alert.warning { background-color: #ffc107; }
    .alert.danger  { background-color: #dc3545; }
    .alert.info { background-color: #0dcaf0; }
    .alert.light { background-color: #f8f9fa; }
    .alert.dark { background-color: #212529; }


    .fade-in {
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
    }

    @keyframes fadeInOpacity {
        0% { 
            opacity: 0;
            transform: translateX(200px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
</style>