<template>
    <section :id="chart_id" class="row chart">
        <div class="d-flex justify-content-center align-items-end col-1 px-1" v-for="(item, index) in data" :key="index">
            <div class="full-width">
                <div :class="[
                    'item rounded-12 full-width justify-content-center', 
                    (item.value !== 0) ? 'bg-item' : 'bg-secondary', 
                    (key_active === item.key) ? 'bg-item-current' : '']" 
                    :style="(item.value !== 0) ? `height: ${(400 / value_highest) * item.value}px` : '20px'">
                        <span v-if="money">{{ item.value | amountMoney }}</span>
                        <span v-else>{{ item.value }}</span>
                        
                </div>
                <div class="text-center">
                    {{ item.label }}
                </div>
                
                <div class=" text-start vertical_label" v-if="item.vertical_label" >
                    {{ item.vertical_label }}
                </div>
                
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ad-chart',
        props: {
            chart_id: {
                type: String,
                required: false,
                default: 'tab-1',
            },
            data: {
                type: Array,
                required: true
            },
            key_active: {
                type: String,
                required: false,
                default: '',
            },
            money: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        computed: {
            value_highest() {
                let highest = 0

                for(let c in this.data) {
                    highest = (this.data[c].value > highest) ? this.data[c].value : highest
                }
                return highest
            }
        },
        data() {
            return {
                
            }
        },
        methods: {
            
        },
        created() {
            
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/scss/colors.scss';

    .chart {
        height: 400px;

        .item {
            padding: 2px 0px;
            display: flex;
            align-items: flex-end;
        }

        .bg-item {
            background: rgb(0,140,156);
            background: linear-gradient(180deg, rgba(0,140,156,1) 6%, rgba(69,36,115,1) 100%);

            &-current {
                background: none;
                background-color: $info !important;
            }
        }

        .vertical_label {
            transform: rotate(-90deg);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 7rem;
            font-size: 30px;
        }
    }
</style>