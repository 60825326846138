<template>
    <div id="app">
        <component :is="layout || 'section'">
            <router-view/>
        </component>
    </div>
</template>

<script>
    export default {
        name: 'app_main',
        computed: {
            layout() {
                return this.$route.meta.layout
            }
        },
        data() {
            return {
                
            }
        },
        beforeMount() {
            console.log(this.$OneSignalVue)
            this.$OneSignal.init( { appId: "dab0fd93-6650-4fd8-b017-8e4ac561a21b"})
                .then( (result) => {
                    console.log('OneSignal:', result)
                })
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: OpenSans-Regular;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100vw !important;
        text-align: left;
        color: #FFFFFF;
    }
</style>

