<template>
    <section class="row">
        <div class="col" 
             v-for="(item, index) in value" :key="index">

            <div class="d-flex justify-content-center">
                <ad-checkbox 
                    v-model="item.value" 
                    @change="customChangeGroup()"
                    :variant="variant" 
                    :fill="fill" 
                    class="my-2">
                    
                </ad-checkbox>
            </div>

            <div class="d-flex justify-content-center">
                {{ item.label }}
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'ad-group-checkbox',
        props: {
            value: {
                type: Array,
                description: "Array of group checkbox",
                required: false,
                default: () => {
                    return new Array()
                }
            },
            variant: {
                type: String,
                description: "defines the color of the checkbox",
                required: false,
                default: 'success',
                validator: function (value) {
                    let allow_colors = CONFIG.class_colors
                    return allow_colors.includes(value)
                }
            },
            color_text: {
                type: String,
                description: "defines the text color of the chip",
                required: false,
                default: "white",
                validator: function (value) {
                    let allow_colors = ['white', 'dark']
                    return allow_colors.includes(value)
                }
            },
            fill: {
                type: Boolean,
                description: "defines whether the checkbox will be normal or fill style",
                required: false,
                default: false
            }
        },
        methods: {
            // Este methodo se dispara en la ejecución del @CHANGE del tag input original
            customChangeGroup(event) {
                this.$emit('change', this.value)
            },
        }
    }
</script>

<style lang="scss">
    @import '../assets/scss/colors.scss';
</style>