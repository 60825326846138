<template>
    <section class="cbox_container">
        <label :class="`checkbox text-${color_text}`">
            <input :id="_id" :checked="value" v-bind="$attrs" v-on="listeners" type="checkbox">

            <span :class="[`checkmark checkmark-${variant}`, (fill) ? `checkmark-fill-${variant}` : ``]"></span>

            <span class="checkbox__label">{{ label }}</span>
        </label>
    </section>
</template>

<script>
    export default {
        name: 'ad-checkbox',
        props: {
            _id: {
                type: String,
                description: "id of checkbox",
                required: false,
                default: 'cbox-1'
            },
            value: {
                type: Boolean,
                description: "defines if input is checked",
                required: false,
                default: () => {
                    return false
                }
            },
            label: {
                type: String,
                description: "label for the checkbox",
                required: false,
                default: ''
            },
            variant: {
                type: String,
                description: "defines the color of the checkbox",
                required: false,
                default: 'success',
                validator: function (value) {
                    let allow_colors = CONFIG.class_colors
                    return allow_colors.includes(value)
                }
            },
            color_text: {
                type: String,
                description: "defines the text color of the chip",
                required: false,
                default: "white",
                validator: function (value) {
                    let allow_colors = ['white', 'dark']
                    return allow_colors.includes(value)
                }
            },
            fill: {
                type: Boolean,
                description: "defines whether the checkbox will be normal or fill style",
                required: false,
                default: false
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        computed: {
            listeners() {
                return {
                    ...this.$listeners,
                    focus: this.customFocus,
                    blur: this.customBlur,
                    change: this.customChangeValue
                }
            }
        },
        methods: {
            // Este methodo se dispara en la ejecución del @FOCUS del tag input original
            customFocus(event) {
                //console.log('Origin FOCUS',event.target)
                this.$emit('focus', event.target.value)
            },

            // Este methodo se dispara en la ejecución del @BLUR del tag input original
            customBlur(event) {
                //console.log('Origin BLUR', event.target)
                this.$emit('blur', event.target.value)
            },

            // Este methodo se dispara en la ejecución del @CHANGE del tag input original
            customChangeValue(event) {
                // console.log('Origin CHANGE', event.target)
                this.$emit('change', event.target.checked)
            },
        }
    }
</script>

<style lang="scss">
    @import '../assets/scss/colors.scss';

    .cbox_container {

        /* Hide the browser's default checkbox */
        .checkbox input {
            position: absolute;
            cursor: pointer;
            opacity: 0;
            height: 0;
            width: 0;
        }

        .checkbox {
            position: relative;
            margin-bottom: 0px;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            // border: 1px solid $light;
            // background-color: $primary;
            border-radius: 8px;
            margin-right: 12px;

            -webkit-transition: all .5s !important;
            -moz-transition: all .5s !important;
            -ms-transition: all .5s !important;
            -o-transition: all .5s !important;
            transition: all .5s !important;
        }

        .checkmark-primary {
            border: 1px solid $primary !important;  
        }
        .checkmark-secondary {
            border: 1px solid $secondary !important;  
        }
        .checkmark-success {
            border: 1px solid $success !important;  
        }
        .checkmark-warning {
            border: 1px solid $warning !important;  
        }
        .checkmark-danger {
            border: 1px solid $danger !important;  
        }
        .checkmark-info {
            border: 1px solid $info !important;  
        }
        .checkmark-light {
            border: 1px solid $light !important;  
        }
        .checkmark-dark {
            border: 1px solid $dark !important;  
        }

        /* HOVERS */
        .checkbox:hover input ~ .checkmark-primary {
            //background-color: $success;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px $primary !important;
        }
        .checkbox:hover input ~ .checkmark-secondary {
            //background-color: $success;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px $secondary !important;
        }
        .checkbox:hover input ~ .checkmark-success {
            //background-color: $success;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px $success !important;
        }
        .checkbox:hover input ~ .checkmark-warning {
            //background-color: $success;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px $warning !important;
        }
        .checkbox:hover input ~ .checkmark-danger {
            //background-color: $success;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px $danger !important;
        }
        .checkbox:hover input ~ .checkmark-info {
            //background-color: $success;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px $info !important;
        }
        .checkbox:hover input ~ .checkmark-light {
            //background-color: $success;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px $light !important;
        }
        .checkbox:hover input ~ .checkmark-dark {
            //background-color: $success;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 10px $dark !important;
        }


        /* CHECKED FILL */
        .checkbox input:checked ~ .checkmark-fill-primary {
            background-color: $primary;
        }
        .checkbox input:checked ~ .checkmark-fill-secondary {
            background-color: $secondary;
        }
        .checkbox input:checked ~ .checkmark-fill-success {
            background-color: $success;
        }
        .checkbox input:checked ~ .checkmark-fill-warning {
            background-color: $warning;
        }
        .checkbox input:checked ~ .checkmark-fill-danger {
            background-color: $danger;
        }
        .checkbox input:checked ~ .checkmark-fill-info {
            background-color: $info;
        }
        .checkbox input:checked ~ .checkmark-fill-light {
            background-color: $light;
        }
        .checkbox input:checked ~ .checkmark-fill-dark {
            background-color: $dark;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        /* Show the checkmark when checked */
        .checkbox input:checked ~ .checkmark:after {
            display: block;
        }
        /* Style the checkmark/indicator */
        .checkbox .checkmark:after {
            top: 6px;
            left: 9px;
            width: 5px;
            height: 10px;

            // border: solid $light;
            // border-width: 0 2px 2px 0 !important;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .checkbox .checkmark-primary:after {
            border: solid $primary !important;
            border-width: 0 2px 2px 0 !important;
        }
        .checkbox .checkmark-secondary:after {
            border: solid $secondary !important;
            border-width: 0 2px 2px 0 !important;
        }
        .checkbox .checkmark-success:after {
            border: solid $success !important;
            border-width: 0 2px 2px 0 !important;
        }
        .checkbox .checkmark-warning:after {
            border: solid $warning !important;
            border-width: 0 2px 2px 0 !important;
        }
        .checkbox .checkmark-danger:after {
            border: solid $danger !important;
            border-width: 0 2px 2px 0 !important;
        }
        .checkbox .checkmark-info:after {
            border: solid $info !important;
            border-width: 0 2px 2px 0 !important;
        }
        .checkbox .checkmark-light:after {
            border: solid $light !important;
            border-width: 0 2px 2px 0 !important;
        }
        .checkbox .checkmark-dark:after {
            border: solid $dark !important;
            border-width: 0 2px 2px 0 !important;
        }

        .checkbox .checkmark-fill-primary:after, .checkbox .checkmark-fill-secondary:after, .checkbox .checkmark-fill-success:after,
        .checkbox .checkmark-fill-warning:after, .checkbox .checkmark-fill-danger:after, .checkbox .checkmark-fill-info:after,
        .checkbox .checkmark-fill-dark:after {
            border: solid $light !important;
            border-width: 0 2px 2px 0 !important;
        }

        .checkbox .checkmark-fill-light:after {
            border: solid $dark !important;
            border-width: 0 2px 2px 0 !important;
        }
        // EFECTO INTERESANTE 
        // .checkbox input:checked ~ .checkmark-fill-primary {
        //     border: solid $light !important;
        //     border-width: 0 2px 2px 0 !important;

        //     -webkit-transform: rotate(45deg);
        //     -ms-transform: rotate(45deg);
        //     transform: rotate(45deg);
        // }

        .checkbox__label {
            margin-left: 30px;
        }
    }
</style>