import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

// Modules
import Login from 'ROUTER/modules/login.js'
import Dashboard from 'ROUTER/modules/dashboard'
import Catalogues from 'ROUTER/modules/catalogues'
import Articles from 'ROUTER/modules/articles'
import Talks from  'ROUTER/modules/talks'
import Recipes from 'ROUTER/modules/recipes'
import Orders from 'ROUTER/modules/orders'
import Videos from 'ROUTER/modules/videos'
import Notifications from 'ROUTER/modules/notifications'
import Programs from 'ROUTER/modules/programs'
import Murals from 'ROUTER/modules/murals'
import Products from 'ROUTER/modules/products'
import controlUsers from 'ROUTER/modules/controlUsers'



const routes = [
    ...Login,
    ...Dashboard,
    ...Catalogues,
    ...Talks,
    ...Articles,
    ...Recipes,
    //...Orders,
    ...Videos,
    ...Notifications,
    ...Programs,
    ...Murals,
    //...Products
    ...controlUsers
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


const allowed_without_session = ['login']
const allowed_with_session = [
        'articles',
        'food-passport',
        'catalogues-recipes',
        'catalogues-articles',
        'catalogues-aliments',
        'catalogues-prospects',
        'catalogues-authors',
        'catalogues-users', 
        'murals', 
        'notifications',
        'orders',
        'products',
        'programs',
        'recipes',
        'talks',
        'videos',
        'control-users'
    ]


//roles 1 = administrador, 2 = Editor, 3 = ventas

router.beforeEach((to, from, next) => {

    const session = store.getters['getSession']

    if(!session && to.name == 'login') {
        next()
    }
    
    else if((!allowed_without_session.includes(to.name) && session == null) || to.name == null) {
        next({
            name: 'login'
        })
    }

    else if(allowed_with_session.includes(from.name)  && to.name === 'login') {
       
        next(false);
    }

    else if(!from.name  && (to.name === 'catalogues-recipes' ||  to.name === 'catalogues-articles' || to.name === 'catalogues-aliments' || to.name === 'catalogues-prospects' || to.name === 'catalogues-authors' || to.name === 'catalogues-users') && session.id_portal_role != 1) {
       
        if(session.id_portal_role == 1 || session.id_portal_role == 2  )  {
            next({
                name: 'articles'
            })
        }

        else {
            next({
                name: 'murals'
            })
        }
    }

    else if(!from.name  && (to.name === 'murals' ||  to.name === 'orders' || to.name === 'products') && (session.id_portal_role != 1 && session.id_portal_role != 3)) {
       
        next({
            name: 'articles'
        })
    }

    else if(!from.name  && (to.name === 'articles' ||  to.name === 'recipes' || to.name === 'food-passport' || to.name === 'talks' || to.name === 'videos' || to.name === 'programs') && (session.id_portal_role != 1 && session.id_portal_role != 2)) {
       
        next({
            name: 'murals'
        })
    }

    else if((!from.name && to.name == 'login') && session !== null) {
        if(session.id_portal_role == 1 || session.id_portal_role == 2  )  {
            next({
                name: 'articles'
            })
        }

        else {
            next({
                name: 'murals'
            })
        }
        
    }

    else if(session && to.name == null) {
        if(session.id_portal_role == 1 || session.id_portal_role == 2  )  {
            next({
                name: 'articles'
            })
        }

        else {
            next({
                name: 'murals'
            })
        }
    }

    else {
        next()
    }

    
  })

export default router
