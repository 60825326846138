<template>
    <div class="col-12 d-flex justify-content-center my-5">
        <div :class="`loader ${variant}`" 
            :style="`${(size == 'lg') ? 'height: 140px; width: 140px;' : 
            (size == 'md') ? 'height: 120px; width: 120px; border-width: 14px;' : 
            (size == 'sm') ? 'height: 70px; width: 70px; border-width: 12px;' : 
            'height: 40px; width: 40px; border-width: 10px;'}`">

        </div>
    </div>
</template>

<script>
    export default {
        name: 'ad-chip',
        props: {
            variant: {
                type: String,
                description: "defines the color of the loader",
                required: false,
                default: 'primary',
                validator: function (value) {
                    let allow_colors = CONFIG.class_colors.concat(
                        ['white', 'white-light', 'white-dark', 'transparent', 'transparent-light', 'transparent-dark'])
                    return allow_colors.includes(value)
                }
            },
            size: {
                type: String,
                description: "size for the chip",
                required: false,
                default: 'md',
                validator: function (value) {
                    let allow_sizes = ['xs', 'sm', 'md', 'lg']
                    return allow_sizes.includes(value)
                }
            },
        }
    }
</script>

<style lang="scss">
    @import '../assets/scss/colors.scss';

    .loader {
        border: 16px solid white; /* #f3f3f3; Light grey */
        border-top: 16px solid #13b170; /* Green */
        border-radius: 50%;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .loader.primary { border-top-color: $primary; }
    .loader.secondary { border-top-color: $secondary; }
    .loader.success { border-top-color: $success; }
    .loader.warning { border-top-color: $warning; }
    .loader.danger { border-top-color: $danger; }
    .loader.info { border-top-color: $info; }
    .loader.light { border-top-color: $light; }
    .loader.dark { border-top-color: $dark; }
</style>