module.exports = {
	env: "prod",
	server: {
		local: {
			page_url: 'http://localhost:8080',
			api: 'http://localhost:3201',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
				"reference": "com.metabolismomexico.academia.slimapp",
				'Authorization': 'Bearer WY5Xulq+zBF8F62P7LuNOOpOLHJpCEnV+1zvyA0e9z2IpNUf7nylZ4PQVwhifP6F'
			}
		},
		dev: {
			page_url: 'https://admin.naturalslim.fit',
			api: 'https://api.naturalslim.fit',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
				"reference": "com.metabolismomexico.academia.slimapp",
				'Authorization': 'Bearer WY5Xulq+zBF8F62P7LuNOOpOLHJpCEnV+1zvyA0e9z2IpNUf7nylZ4PQVwhifP6F'
			}
		},
		prod: {
			page_url: 'https://admin.naturalslim.fit',
			api: 'https://api.naturalslim.fit',
			headers: {
				'Accept': 'application/json, text/plain, /',
            	'Content-Type': 'application/json',
				"reference": "com.metabolismomexico.academia.slimapp",
				'Authorization': 'Bearer WY5Xulq+zBF8F62P7LuNOOpOLHJpCEnV+1zvyA0e9z2IpNUf7nylZ4PQVwhifP6F'
			}
		}
	},
	class_colors: ['primary',	'primary-light', 'primary-dark', 'secondary', 'secondary-light', 'secondary-dark', 
		'success', 'success-light', 'success-dark', 'warning', 'warning-light', 'white','warning-dark', 'danger', 
		'danger-light', 'danger-dark', 'info', 'info-light', 'info-dark', 'light', 'light-light', 'light-dark', 
		'dark', 'dark-light', 'dark-dark', 'light-green'],
}