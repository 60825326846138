import sendRequest from "../../libs/sendRequest";

export const users = {
    namespaced: true,
    state: {
        user: [],
        users: [],
        last_page: false,
        total_pages: null
    },
    getters: {
        getUser: (state) => (state.user),
        getUsers: (state) => (state.users),
        getLastPage: (state) => (state.last_page),
        getTotalPages: (state) => (state.total_pages)
    },
    mutations: {
        setUser: (state, payload) => (state.user = payload),
        setUsers: (state, payload) => (state.users = payload),
        setLastPage: (state, payload) => state.last_page = payload,
        setTotalPages: (state, payload) => state.total_pages = payload
    },
    actions: {
        list: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            let query = {
                datatable: payload.datatable,
                page: payload.page,
                rows: payload.rows,
                search: '',
                order_by: 'id',
                order_asc: payload.order_asc,
                status: payload.status
            }

            if(payload.search) {
                query.search = payload.search
            }

            const response = await sendRequest('portal/users/list', 'GET', null, null, query, custom_headers)

            if(response.success && response.data) {

                if(query.datatable) {
                    commit('setUsers', response.data.list.result)
                    commit('setTotalPages', response.data.list.total_pages)
                    if(response.data.list.total_pages == query.page) {
                        commit('setLastPage', true)
                    }
                    else {
                        commit('setLastPage', false)
                    }
                }

                else {
                    commit('setUsers', response.data.list)
                }
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        view: async ({ commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('portal/users/view', 'GET', null, null, payload, custom_headers)

            if(response.success && response.data) {
                commit('setUser', response.data.view)
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        add: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders


            const response = await sendRequest('portal/users/add', 'POST', null, payload, null, custom_headers)

            if(response.success && response.data) {
                commit('setAlert', {
                    open: true,
                    message: 'Elemento añadido con éxito',
                    text_color: 'success'
                  }, { root: true})

            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true,
                    message: 'Un problema ha ocurrido intente más tarde.',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        update: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const body = payload.body

            const query = payload.query

            const response = await sendRequest('portal/users/update', 'PUT', null, body, query, custom_headers)

            if(response.success && response.data) {
                console.log('updated')
                commit('setAlert', {
                    open: true,
                    message: 'Elemento actualizado con exito.',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true,
                    message: 'Un problema ha ocurrido intente más tarde.',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        },
        delete: async ({commit, rootGetters}, payload) => {
            const custom_headers = rootGetters.getHeaders

            const response = await sendRequest('portal/users/inactive', 'DELETE', null, null, payload, custom_headers)

            if(response.success && response.data) {
                console.log('deleted')
                commit('setAlert', {
                    open: true,
                    message: 'Elemento eliminado con exito.',
                    text_color: 'success'
                  }, { root: true})
            }

            if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
                commit('setAlert', {
                    open: true,
                    message: 'Un problema ha ocurrido intente más tarde.',
                    text_color: 'danger'
                  }, { root: true})
            }

            if(response.success && !response.data) {
                console.log('se perdio la conexión con la API')
            }
        }
    }
}