<template>
    <div :id="_id" 
        :class="[ `chip bg-${variant} p-2`, (full) ? 'w-100' : '', (slots) ? 'justify-content-between' : '' ]"
        :style="(size == 'lg') ? 'min-height: 55px;' : (size == 'md') ? 'min-height: 44px;' : (size == 'sm') ? 'height: 32px;' : 'height: 22px;' ">

        <div class="" style="width: 50px;" v-if="slots">
            <slot name="left">
                
            </slot>
        </div>

        <slot class="d-flex justify-content-center">
            <span :class="`text-${color_text}`">
                {{ label }}
            </span>
        </slot>

        <div class="" style="width: 50px;" v-if="slots">
            <slot name="right">
                
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ad-chip',
        props: {
            _id: {
                type: String,
                description: "id of html div",
                required: false,
                default: 'chip-1',
            },
            label: {
                type: String,
                description: "label for the chip",
                required: false,
                default: '',
            },
            variant: {
                type: String,
                description: "defines the color of the chip",
                required: false,
                default: 'primary',
                validator: function (value) {
                    let allow_colors = CONFIG.class_colors.concat(
                        ['white', 'white-light', 'white-dark', 'transparent', 'transparent-light', 'transparent-dark'])
                    return allow_colors.includes(value)
                }
            },
            color_text: {
                type: String,
                description: "defines the text color of the chip",
                required: false,
                default: "white",
                validator: function (value) {
                    let allow_colors = CONFIG.class_colors.concat(['white'])
                    return allow_colors.includes(value)
                }
            },
            size: {
                type: String,
                description: "size for the chip",
                required: false,
                default: 'lg',
                validator: function (value) {
                    let allow_sizes = ['xs', 'sm', 'md', 'lg']
                    return allow_sizes.includes(value)
                }
            },
            full: {
                type: Boolean,
                default: 'defines whether the inputs will be as wide as possible',
                required: false,
                default: false
            },
            slots: {
                type: Boolean,
                default: '',
                required: false,
                default: false
            }
        }
    }
</script>

<style lang="scss">
    .chip {
        border-radius: 44px;
        //min-height: 55px;
        width: 100px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>