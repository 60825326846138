<template>
    <div id="template-default" class="p-0 container-fluid m-0">
		<header>
			<div class="bg-success pl-5">
				<img :src="logo_letters"/>
			</div>
		</header>
        <slot></slot>
		<ns-alert />
		<ns-multi-alert></ns-multi-alert>
	</div>
</template>

<script>

	//images
	import logo_letters from 'ASSETS/images/natura_logo_letters.png';

	export default {
		name: 'template-default',
		data() {
			return {
                logo_letters
			}
		}
	}
</script>


<style lang="scss" scoped>
    #template-default {
    	
    }
</style>