<template>
    <div id="ad-modal" :class="['modal ', `${show_modal ? 'd-flex' : 'd-none'}`]" @click.stop="closeModal('overlay')">
        <section class="modal__container mx-5" :style="`max-width: ${max_width}px !important;`">
            
            <!-- Header -->
            <div @click.stop="closeModal('content')" v-if="header"
                :class="['row modal__header',
                        (title !== null) ? `bg-${bg_color_header}` : 'bg-dark']">

                    <div class="col-12 p-0">
                        <div class="row">
                            <div class="col-12">
                                <span class="ad--title-sm text-white">
                                    {{ title }}
                                </span>
                            </div>

                            <button @click.stop="closeModal('button')" 
                                class="modal__header--button bg-transparent position-absolute">
                                    <span class="text-white close">&times;</span>
                            </button>
                        </div>
                    </div>            
            </div>

            <!-- Content -->
            <div @click.stop="closeModal('content')" 
                :class="['row modal__content',`bg-${bg_color}`, (header) ? '' : 'headerless']">

                    <div class="col-12 p-0 m-0">
                        <div class="row p-0 m-0">
                            <slot>
                                <!-- Slot default -->
                            </slot>    
                        </div>
                    </div>            
            </div>

        </section>
    </div>
</template>

<script>
    export default {
        name: 'ad-modal',
        props: {
            show_modal: {
                require: true,
                type: Boolean,
                default: false
            },
            header: {
                required: false,
                type: Boolean,
                default: false
            },
            title: {
                require: false,
                type: String,
                default: 'title'
            },
            overlay_close: {
                require: false,
                type: Boolean,
                default: false
            },
            bg_color_header: {
                require: false,
                type: String,
                default: 'success',
                validator: function (value) {
                    // El valor debe coincidir con una de estas cadenas de texto
                    let allow_colors = CONFIG.class_colors
                    return allow_colors.includes(value)
                }
            },
            bg_color: {
                require: false,
                type: String,
                default: 'dark',
                validator: function (value) {
                    // El valor debe coincidir con una de estas cadenas de texto
                    let allow_colors = CONFIG.class_colors
                    return allow_colors.includes(value)
                }
            },
            max_width: {
                require: false,
                type: [String, Number],
                default: '900'
            },
        },
        data() {
            return {
                
            }
        },
        methods: {
            closeModal(type) {
                if(type === 'content') {
                    return
                }
                if(type === 'overlay') {
                    if(!this.overlay_close) {
                        return
                    }
                }
                this.$emit('onClose')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal {
        justify-content: center;
        align-items: center;
        background-color: #00000022;
        z-index: 9;

        &__container {
            width: 100%;
            border-radius: 12px;
        }

        &__header {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            z-index: 10000;
            padding: 10px;

            &--button {
                border-radius: 0.25rem !important;
                cursor: pointer;
                border: 0px;
                right: 0px; 
                z-index: 10001;

                span {
                    font-size: 34px;
                }
            }
        }

        &__content {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            z-index: 10000;
            padding: 20px 10px;
            overflow-y: scroll;
            max-height: 85vh;
        }

        .headerless {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
    }
</style>
    