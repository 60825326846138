<template>
    <div class="select_container position-relative">
        <span class="text-dark">
            {{ label }}
        </span>
        <!------ custom input container -------->
        <div  @click.stop="open = !open"
            class="current_option text-dark" style="height: 50px;"
            :class="[
                (rounded) ? 'btn-pill' : '',
                (full) ? 'full-width':'',
                (bg_color) ? `bg-${bg_color}` : 'bg-primary'
            ]">

            <div v-for="item, index in current" :key="index"
                class="d-flex py-2 align-items-center pl-2 mb-1 position-relative">
                <img width="35px"
                    :src="item.icon" />
                <p class="pl-2 m-0">
                    {{ item.label }} 
                </p>

                <img class="position-absolute"
                    style="right: 10px;"
                    :src="arrow_down" />
            </div>
            
        </div>

        <div class="text-dark bg-light position-absolute" v-if="open" 
            style="border-radius: 15px; z-index: 20; left: 0; right: 0; top: 25px;">
            <div v-for="item, index in options" :key="index"
                class="d-flex py-2 align-items-center pl-2 cursor-pointer option"
                @click.stop="setCurrentValue(index)">
                <img width="35px"
                    :src="item.icon" />
                <p class="pl-2 m-0">
                    {{ item.label }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    //icons
    import arrow_down from 'ASSETS/icons/arrow_down.png'


    export default {
        data(){
            return {
                arrow_down,
                current_value: [],
                open: false
            };
        },
        emits:['selected:option'],
        props: {
            current: {
                type: Array,
                required: false
            },
            label: {
                type: String,
                required: false,

            },
            modelValue: 'modelValue',
            bg_color: {
                type: String,
                required: false,
                default: null
            },
            full: {
                type: Boolean,
                required: false,
                default: false
            },
            rounded: {
                type: Boolean,
                required: false,
                default: false
            },
            options:{
                type: Array,
                required: false,
                default() {
                    return [{id: 0, label: 'Seleccione...'},{id: 1, label: 'Placeholder value 1'},{id: 2, label: 'Placeholder value 2'},{id: 3, label: 'Placeholder value 3'}]
                }
            }
        },
        methods: {
            setCurrentValue(index){
                let item = this.$props.options[index]

                this.current_value = [item]
                this.open = false
                this.$emit('selected:option', item)
            }
        }
    }
</script>

<style lang="scss" scoped>
    .option {
        
        &:hover{
            outline: 1px solid #237727;
            border-radius: 15px;
        }
    }
</style>
