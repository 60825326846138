<template>
    <div id="template-session">

		<div class="header_area">
			<header>
				<div class="bg-success pl-5  d-flex justify-content-between align-items-center" style="z-index: 2; height: 75px;">
					<img :src="logo_letters"/>
					<p class="m-0 mr-5 pr-3  montserrat-bold" style="font-size: 32px;">
						{{ title }}
					</p>
				</div>
			</header>
		</div>

		<div class="sidebar_area">
			<sidebar bg_color="light-green"></sidebar>
		</div>

		<div class="main_area container-fluid pl-2">
			<slot></slot>
		</div>

		<ns-alert />
		<ns-multi-alert></ns-multi-alert>
	</div>
</template>

<script>
	//images
	import logo_letters from 'ASSETS/images/natura_logo_letters.png';

	import Sidebar from '../components/Sidebar.vue'

	//helpers
	import { mapGetters } from 'vuex'

	export default {
		name: 'template-session',
		components: {
            'sidebar': Sidebar,
        },
		data() {
			return {
                logo_letters
			}
		},
		computed: {
			...mapGetters({
				title: 'getTitle'
			})
		}
	}
</script>


<style lang="scss" scoped>
    #template-session {
        //padding: 0px;
		width: 100%;
		min-height: 100vh;
		display: grid;
		grid-template-columns: 250px calc(100vw - 250px);
		grid-template-rows: 75px calc(100% - 75px);

		.header_area {
			grid-column: 1 / 3;
			grid-row: 1 / 2;
		}

		.sidebar_area {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
		}

		.main_area {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
        
    }
</style>